import React from 'react';
import { Link as LinkElemet } from 'react-scroll';
import Link from '@components/link';
import { LinkIcons } from '@components/Icon/Icon';
import { constant } from '@pages/home';
import { IFooterLanding, ROUTES } from '.';
import './FooterLanding.scss';

const FooterLanding: React.FC<IFooterLanding> = ({ sizeScreen }) => {
    const openPdf = (url: string): void => {
        const pdfUrl = process.env.PUBLIC_URL + url;
        window.open(pdfUrl, '_blank');
    };

    return (
        <div className="w-full bg-blue p-4.5">
            <div className="flex justify-center">
                <div className="class-container">
                    <div className="flex gap-x-16 mb-5.5 xs:gap-x-7">
                        <LinkIcons
                            nameIcon={sizeScreen > constant.sizeScreen ? 'logoLinkedinFooter' : 'logoLinkedinHeader'}
                            href={ROUTES.LINKEDIN}
                            classIcon="class-icon"
                            target="_blank"
                        />
                        <LinkIcons
                            nameIcon={sizeScreen > constant.sizeScreen ? 'logoInstagramFooter' : 'logoInstagramHeader'}
                            href={ROUTES.INSTAGRAM}
                            classIcon="class-icon"
                            target="_blank"
                        />
                        <LinkIcons
                            nameIcon={sizeScreen > constant.sizeScreen ? 'logoFacebookFooter' : 'logoFacebookHeader'}
                            href={ROUTES.FACEBOOK}
                            classIcon="class-icon"
                            target="_blank"
                        />
                        <LinkIcons
                            nameIcon={sizeScreen > constant.sizeScreen ? 'logoTwitterFooter' : 'logoTwitterHeader'}
                            href={ROUTES.TWITTER}
                            classIcon="class-icon"
                            target="_blank"
                        />
                    </div>
                    <div className="text-white class-container xs:text-xtiny">
                        <p className="text-center">
                            Centro de Consultoría para la Competitividad CCxC Colombia SAS &nbsp; • &nbsp; NIT 901.084.754-3
                            &nbsp; • &nbsp;  Calle 26 #68C-61 &nbsp; - &nbsp; Código postal: 111321
                            <br/> Edificio Torre Central, oficina 301  
                        </p>
                        <div className="flex mt-1 xs:mt-2">
                            <Link href={ROUTES.TERMS} text="Términos y condiciones" target="_blank" outside classes="underline" />
                            <p className="mx-1">&nbsp;•</p>
                            <Link
                                href={ROUTES.DATA_TREATMENT_PRIVATE_THIRD_PARTIES}
                                text="Política de Tratamiento de Datos Personales"
                                classes="mx-1 underline cursor-pointer"
                                target="_blank"
                                outside
                            />
                        </div>
                        <div className="flex mt-1 xs:mt-2">
                            <p
                                className="mx-1 underline cursor-pointer"
                                onClick={(): void => openPdf(ROUTES.DATA_TREATMENT_SECURITY_INFORMATION)}
                            >
                                Política de seguridad de la información y la privacidad
                            </p>
                            <p className="mx-1">•&nbsp;</p>
                            <LinkElemet offset={-50} smooth to="contact-us" className="underline cursor-pointer">
                                Sugerencias y reclamos
                            </LinkElemet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FooterLanding;
